import React, { useRef, useState, useEffect, useContext } from "react";
import clsx from "clsx";
import FigureWithGradientPlaceholder from "../ui/FigureWithGradientPlaceholder";
import { _getPaddingCss } from "../../core/utils";
import { WrapperContext } from "../Layout";
import gsap from "gsap";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const ImagesScrollerItem = ({ image, pagination, i }) => {
  const { isMobile } = useContext(WrapperContext);
  // console.log("isMobile", isMobile);
  const [dimensions, setDimensions] = useState({ with: 0, height: 0 });

  const _setImgRelativeDimensions = () => {
    if (typeof window === "undefined") return 0;

    const WindowWidth = window.innerWidth;
    // const isTouch =
    //   "ontouchstart" in window ||
    //   navigator.maxTouchPoints ||
    //   WindowWidth < 1080;

    //let's scale the images
    const reduceFactor = isMobile ? WindowWidth / 2500 : WindowWidth / 4500;
    // const reduceFactor = WindowWidth / 4500;
    // console.log(reduceFactor, WindowWidth);
    const innerWidthPerc = WindowWidth * reduceFactor;
    const width = (image.dimensions.width * innerWidthPerc) / WindowWidth;
    const height = width / image.fluid?.aspectRatio;

    return { width, height };
  };

  useEffect(() => {
    _format();

    window.addEventListener("resize", _format);

    return () => window.removeEventListener("resize", _format);
  }, [setDimensions]);

  const _format = () => {
    setDimensions(_setImgRelativeDimensions());
  };

  return (
    <figure className=''>
      <FigureWithGradientPlaceholder
        input={image}
        imgStyle={{
          // height: dimensions.height,
          width: `${dimensions.width}px`,
        }}
      />
      {/* <div>{dimensions.width}</div> */}
      <figcaption
        className={clsx(
          "text-xs md:text-sm italic text-secondary py-xs",
          pagination ? "" : "opacity-0"
        )}>
        fig <span className='index'>{i + 1}</span>
      </figcaption>
    </figure>
  );
};

const ImagesScroller = ({ input }) => {
  // console.log(input);
  const {
    items,
    primary: { padding },
  } = input;

  const scrollerRef = useRef();
  const [ready, setReady] = useState();

  useEffect(() => {
    // _format();
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) _setItemsGutter();
  }, [ready]);

  const _swipe = (e) => {
    if (
      ("ontouchstart" in window || navigator.maxTouchPoint) &&
      window.innerWidth < 1280
    ) {
      return;
    }

    const direction = e.screenX < window.innerWidth / 2 ? "left" : "right";
    const gap = window.innerWidth / 2;
    const scrollAmount =
      direction === "left"
        ? scrollerRef.current.scrollLeft - gap
        : scrollerRef.current.scrollLeft + gap;
    // console.log(e.screenX, window.innerWidth, direction);
    // console.log(elementScrollIntoViewPolyfill);
    // console.log(scrollerRef.current.scrollLeft, scrollAmount);
    gsap.to(scrollerRef.current, {
      // duration: 0.6,
      scrollTo: { x: scrollAmount, autoKill: true },
      ease: "power2",
    });

    // elementScrollIntoViewPolyfill(
    //   scrollerRef.current.scrollTo({
    //     top: 0,
    //     left: scrollAmount,
    //     behavior: "smooth",
    //   })
    // );
  };

  const _setItemsGutter = () => {
    const scrollerItems = scrollerRef.current.querySelectorAll(".item");

    if (scrollerItems) {
      scrollerItems.forEach((el, i) => {
        const { gutter } = items[i];
        // console.log(items[i].gutter);
        el.style.padding = _getFigureGutterCss(gutter, "px");
      });
    }
  };

  const _getFigureGutterCss = (gutter, unit) => {
    if (!gutter) return 0;

    const IS_BROWSER = typeof window !== "undefined";
    const isMobile = IS_BROWSER && window.innerWidth < 1280;

    const parts = gutter.replace(" ", "").split(",");
    let css = "";
    parts.forEach((el, i) => {
      let val = isMobile ? el / 2 : el;

      let _unit = unit;
      if (unit === "vw") {
        _unit = isOdd(i) ? "vw" : "vh";
      }
      // css += `${el}${_unit} `;
      css += `${val}${_unit} `;
    });
    return css;
  };

  // const _widthToViewPortWith = (width) => {
  //   if (typeof window === "undefined") return 0;
  //   return `${(width * 100) / innerWidth}vw`;
  // };

  // const _getValueIfMobile = (val) => {
  //   // return val;
  //   if (!isMobile) return val;
  //   return isMobile ? val / 2 : val;
  // };

  // const getFigureGutterCss = (gutter) => {
  //   if (!gutter) return 0;
  //   const parts = gutter.split(",");
  //   return `0 ${_getValueIfMobile(parts[1])}px 0 ${_getValueIfMobile(
  //     parts[0]
  //   )}px`;
  // };

  const sectionPadding = padding ? _getPaddingCss(padding, "px") : "0";

  return (
    <section className='slice images-scroller' onClick={(e) => _swipe(e)}>
      {ready && (
        <div
          ref={scrollerRef}
          className='inner flex items-baseline w-screen overflow-x-scroll scroll-smooth-'
          style={{
            padding: sectionPadding,
          }}>
          {items.map(({ image, gutter, pagination }, i) => (
            <div
              className={clsx(
                "item images-scroller--item px-sm flex-shrink-0",
                pagination ? "has-pagination" : ""
              )}
              key={i}
              style={
                {
                  // padding: getFigureGutterCss(gutter),
                  // padding: _getPaddingCss(gutter, "px"),
                }
              }>
              <ImagesScrollerItem image={image} pagination={pagination} i={i} />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ImagesScroller;
