import React from "react";
import { RichText } from "prismic-reactjs";
import ImagesScroller from "./slices/ImagesScroller";
import Image from "./slices/Image";
import ImageFull from "./slices/ImageFull";
import Video from "./slices/Video";
// import Pre from "./utils/Pre";
import ProjectHeader from "./ProjectHeader";
import { _linkResolver } from "../core/utils";
// import { pad2 } from "../core/utils";

const ProjectArticle = ({ input, index }) => {
  // const { uid } = input;
  const { uid } = input;
  const { title, texte, body } = input.data;

  const slices = body.map((slice, i) => {
    // console.log(slice.slice_type);
    switch (slice.slice_type) {
      case "images_scroller":
        return <ImagesScroller key={i} input={slice} alt={title.text} />;
      case "image":
        return <Image key={i} input={slice} alt={title.text} />;
      case "image_full":
        return <ImageFull key={i} input={slice} alt={title.text} />;
      case "video":
        return <Video key={i} input={slice} />;

      default:
        return null;
    }
  });

  return (
    <article
      className='project-article mb-lg md:mb-xl'
      id={uid}
      data-index={index}>
      <div className='sticky top-0 z-50 pointer-events-none '>
        <ProjectHeader input={input} index={index} h2 />
      </div>

      <div className='body'>{slices}</div>
      <div className='texte footnote flex- justify-end- px-sm md:px-md md:pr-lg'>
        <div className='inner'>
          <div className='flex items-baseline'>
            {/* <div className='footnote-index pr-xs text-secondary italic'>
              {index}
            </div> */}
            <div className='text-md'>
              {<RichText render={texte.raw} linkResolver={_linkResolver} />}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProjectArticle;
