import React, { useContext, useState, useEffect } from "react";
// import Img from "gatsby-image";
// import ImgWithDominantPlaceholder from "../ui/ImgWithDominantPlaceholder";
// import { WrapperContext } from "../Layout";
import FigureWithGradientPlaceholder from "../ui/FigureWithGradientPlaceholder";
import { _getPaddingCss } from "../../core/utils";
import clsx from "clsx";

const Image = ({ input, alt }) => {
  // console.log(input);
  const { padding, centered, image } = input.primary;
  const [dimensions, setDimensions] = useState({ with: 0, height: 0 });
  // const { isMobile } = useContext(WrapperContext);

  useEffect(() => {
    _format();

    window.addEventListener("resize", _format);

    return () => window.removeEventListener("resize", _format);
  }, [setDimensions]);

  const _format = () => {
    setDimensions(_setImgRelativeDimensions());
  };

  // const getPaddingCss = () => {
  //   const parts = padding.replace(" ", "").split(",");
  //   return `${parts[0]}vh ${parts[1]}vw ${parts[2]}vh ${parts[3]}vw`;
  // };

  // const sectionPadding = padding ? getPaddingCss() : "0";
  const sectionPadding = padding ? _getPaddingCss(padding, "px") : "0";
  // console.log(image);
  const isSvg = image.url.indexOf("svg") > -1;
  // const maxWidth = isSvg ? "none" : image.dimensions.width;

  const _setImgRelativeDimensions = () => {
    if (typeof window === "undefined") return 0;

    const WindowWidth = window.innerWidth;
    const isTouch =
      "ontouchstart" in window ||
      navigator.maxTouchPoints ||
      WindowWidth < 1080;

    //let's scale the images
    // const reduceFactor = isTouch ? 0.3 : WindowWidth / 4500;
    const reduceFactor = WindowWidth / 3500;
    // console.log(reduceFactor, WindowWidth);
    const innerWidthPerc = WindowWidth * reduceFactor;
    const width = (image.dimensions.width * innerWidthPerc) / WindowWidth;
    const height = width / image.fluid?.aspectRatio;

    return { width, height };
  };
  // console.log("centered", centered);
  return (
    <section
      className={clsx(
        "slice image min-h-screen- has-pagination px-md",
        centered ? "is-centered" : ""
      )}>
      <div className='inner' style={{ padding: sectionPadding }}>
        <figure
          style={{
            maxWidth: "100vw",
            // minWidth: image.dimensions.width,
            width: dimensions.width,
          }}>
          {isSvg ? (
            <img src={image.url} />
          ) : (
            // <Img
            //   fluid={image.fluid}
            //   alt={image.alt}
            //   style={{
            //     backgroundImage: `linear-gradient(to bottom, #bcbebe, white)`,
            //   }}
            // />
            <FigureWithGradientPlaceholder input={image} alt={alt} />
          )}
          {/* <ImgWithDominantPlaceholder input={image} /> */}
          <figcaption className='text-xs md:text-sm italic text-secondary py-xs md:pl-0'>
            <span>fig</span> <span className='index'></span>
          </figcaption>
          {/* {image.dimensions.width} */}
        </figure>
      </div>
    </section>
  );
};

export default Image;
