import React, { useEffect, lazy, Suspense } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import SEO from "../components/seo";
import ProjectArticle from "../components/ProjectArticle";
// const ProjectArticleLazy = lazy(() => import("../components/ProjectArticle"));
// import { CategoryContext } from "../components/Layout";

export const pageQuery = graphql`
  query CategoryByUid($uid: String!) {
    prismicCategory(uid: { eq: $uid }) {
      data {
        title {
          text
        }
      }
    }
    allPrismicProject(
      sort: { fields: data___order }
      filter: {
        data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
      }
    ) {
      nodes {
        uid
        type
        data {
          ...project
        }
      }
    }
  }
`;

const PageCategory = ({
  path,
  pageContext,
  data: { prismicCategory, allPrismicProject },
}) => {
  const { template } = pageContext;

  useEffect(() => {
    //   console.log("dispatchCurrentCategory", dispatchCurrentCategory);
    //   dispatchCurrentCategory(prismicCategory.data.title.text);
    _handlePagination();
  }, []);

  const _handlePagination = () => {
    setTimeout(() => {
      const articles = document.querySelectorAll(".project-article");
      articles.forEach((el, idx) => {
        const index = el.dataset.index;

        const figcaption = el.querySelectorAll("figcaption");
        let paginationIndex = 1;
        figcaption.forEach((_el) => {
          const spanIndex = _el.querySelector(".index");
          if (spanIndex) {
            _el.querySelector(
              ".index"
            ).innerText = `${index}.${paginationIndex}`;
            paginationIndex++;
          }
        });
      });
    }, 150);
  };

  return (
    <div className='page-template page-category'>
      <SEO
        pageTitle={prismicCategory.data.title.text}
        pageDescription={""}
        // pageImage={image_featured.url}
        template={"template-category"}
        page={true}
        documentClass=''
      />

      <div className='projets-list '>
        {allPrismicProject.nodes.map((project, i) => (
          <ProjectArticle key={project.uid} input={project} index={i + 1} />
        ))}
      </div>
    </div>
  );
};

export default withPreview(PageCategory);
