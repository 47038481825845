import React from "react";
import { _linkResolver, pad2 } from "../core/utils";

const ProjectHeader = ({ input, index }) => {
  // const { uid } = input;
  const { title, client, year, link, link_label } = input.data;
  // console.log(link);
  return (
    <div className='header px-sm md:px-md'>
      <div className='inner '>
        <div
          className='row  custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-site-name hidden-tablet'></div>
          <div className='col-auto col-h1  hidden-tablet'></div>
          <div className='col-auto col-metas flex justify-between'>
            <div className='flex'>
              <div className='index pr-xs md:pr-sm num'>{index}</div>
              <div className='client pr-xs md:pr-sm'>{client}</div>
            </div>
            <div className='year pr-xs- md:pr-sm- num'>{year}</div>
          </div>
          <div className='col-xs col-images'>
            <h2 className='title md:pr-sm '>{title.text}</h2>
          </div>
        </div>
        {/* <div className='link text-secondary italic'>
          {link && link.url && (
            <a
              href={link.url}
              target={link.target === "_blank" ? "_blank" : "_self"}>
              {link_label}
            </a>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default ProjectHeader;
