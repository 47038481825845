import React, { useEffect, useRef, useContext, useState } from "react";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { _getPaddingCss } from "../../core/utils";
import { WrapperContext } from "../Layout";
// import { _getPaddingCss } from "../../core/utils";

const Video = ({ input }) => {
  // console.log(input);
  const { padding, aspect_ratio, video } = input.primary;
  const { isMobile } = useContext(WrapperContext);

  const [dimensions, setDimensions] = useState({ with: 0, height: 0 });
  const aspectRationParts = aspect_ratio ? aspect_ratio.split(":") : [560, 315];
  const ar = (aspectRationParts[1] / aspectRationParts[0]) * 100;
  // const { isMobile } = useContext(WrapperContext);

  useEffect(() => {
    _format();

    window.addEventListener("resize", _format);

    return () => window.removeEventListener("resize", _format);
  }, [setDimensions]);

  const _format = () => {
    setDimensions(_setImgRelativeDimensions());
  };

  // const [play, setPlay] = useState(false);

  const videoRef = useRef();
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (isMobile) {
      videoRef.current.playsinline = true;
      videoRef.current.muted = true;
      videoRef.current.setAttribute("muted", true);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    if (!videoRef.current) return;
    if (inView) {
      if (videoRef.current.paused)
        setTimeout(() => videoRef.current.play(), 150);
    } else {
      if (!videoRef.current.paused)
        setTimeout(() => videoRef.current.pause(), 150);
      // videoRef.current.pause();
    }
  }, [inView]);

  const _onClick = () => {
    if (isMobile) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const _setImgRelativeDimensions = () => {
    if (typeof window === "undefined") return 0;

    const WindowWidth = window.innerWidth;
    const isTouch =
      "ontouchstart" in window ||
      navigator.maxTouchPoints ||
      WindowWidth < 1080;

    //let's scale the images
    // const reduceFactor = isTouch ? 0.3 : WindowWidth / 1500;
    const reduceFactor = WindowWidth / 1500;
    // console.log(reduceFactor, WindowWidth);
    const innerWidthPerc = WindowWidth * reduceFactor;
    const width = (aspectRationParts[0] * innerWidthPerc) / WindowWidth;
    const height = width / ar;

    return { width, height };
  };
  // console.log(videoRef.current.paused);
  // const _getPaddingCss = () => {
  //   const parts = padding.replace(" ", "").split(",");
  //   return `${parts[0]}vh ${parts[1]}vw ${parts[2]}vh ${parts[3]}vw`;
  // };

  // const sectionPadding = padding ? _getPaddingCss() : "0";
  const sectionPadding = padding ? _getPaddingCss(padding, "px") : "0";

  const preload = isMobile ? "none" : inView ? "auto" : "none";
  // console.log(ar);
  return (
    <section
      className={clsx(
        "slice video w-screen has-pagination",
        inView ? "in-view" : "",
        aspectRationParts[0] / aspectRationParts[1] < 1
          ? "is-portrait"
          : "is-landscape"
      )}
      ref={ref}>
      <div className='inner' style={{ padding: sectionPadding }}>
        <div
          className='video-ar '
          style={
            {
              //paddingTop: `${ar}%`
            }
          }>
          <div className='video-ar--inner'>
            <video
              // controls
              width={dimensions.width}
              muted
              loop
              playsInline
              poster={isMobile ? input.primary.poster.url : ""}
              preload={preload}
              ref={videoRef}
              onClick={_onClick}>
              <source src={video.url} type='video/mp4'></source>
            </video>
          </div>
          <figcaption className='text-xs md:text-sm italic text-secondary py-xs'>
            <span>fig</span> <span className='index'></span>
          </figcaption>
          {/* {aspectRationParts[0] / aspectRationParts[1]} */}
        </div>
      </div>
    </section>
  );
};

export default Video;
