import React from "react";
// import Img from "gatsby-image";
// import ImgWithDominantPlaceholder from "../ui/ImgWithDominantPlaceholder";
import FigureWithGradientPlaceholder from "../ui/FigureWithGradientPlaceholder";

const ImageFull = ({ input, alt }) => {
  // console.log(input);
  const { image } = input.primary;
  const isSvg = image.url.indexOf("svg") > -1;
  return (
    <section className='slice image-full w-screen has-pagination'>
      <figure>
        {isSvg ? (
          <img src={image.url} />
        ) : (
          <FigureWithGradientPlaceholder input={image} alt={alt} />
        )}
        <figcaption className='text-sm italic text-secondary py-xs pl-sm md:pl-0'>
          <span>fig</span> <span className='index'></span>
        </figcaption>
      </figure>
    </section>
  );
};

export default ImageFull;
